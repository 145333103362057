import React from 'react'
import { NpmClubInformationPage } from 'src/local_modules'

import devFeatureToggle from '../../devFeatureToggle.json'

const ClubInformacao = () => {
  // prevent content
  if (!devFeatureToggle.hasClubRegister) {
    return null
  }

  return <NpmClubInformationPage />
}

// redirect to 404
export const getServerData = async () => {
  if (devFeatureToggle.hasClubRegister) {
    return {
      status: 200,
    }
  }

  const params = new URLSearchParams({
    from: encodeURIComponent(`/club-informacao`),
  })

  return {
    status: 404,
    props: null,
    headers: {
      location: `/404/?${params.toString()}}`,
    },
  }
}

export default ClubInformacao
